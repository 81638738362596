<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Room detail
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-form-group :label="$t('image')">
      <ImageUpload
        :url="room.room_image"
        ref="image"
        :title="$t('backgroundImage')"
        @updateImage="updateImage"
      />
    </b-form-group>
    <b-form-group :label="$t('name')">
      <b-form-input v-model="room.name"></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('description')">
      <b-textarea v-model="room.description"></b-textarea>
    </b-form-group>
    <b-form-group :label="$t('link')">
      <b-form-input v-model="room.room_url"></b-form-input>
    </b-form-group>
  </b-overlay>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";
import { Room } from "@/models/room.model";

export default {
  components: { ImageUpload },
  data() {
    return {
      isLoading: false,
      room: new Room("", ""),
    };
  },
  methods: {
    updateImage(newUrl) {
      this.room.room_image = newUrl;
    },
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Rooms/fetchById", this.$route.params.id);
      this.isLoading = false;
    },
    async doSave() {
      this.isLoading = true;
      if (this.$route.params.id) {
        await this.$store.dispatch("Rooms/update", this.room);
      } else {
        await this.$store.dispatch("Rooms/create", this.room);
      }
      this.$root.$bvToast.toast("Saved", {
        variant: "success",
      });
      this.doFetch();
      this.isLoading = false;
    },
  },
  async created() {
    if (this.$route.params.id) {
      await this.doFetch();
      this.room = this.$store.getters["Rooms/current"];
    }
  },
};
</script>
<style lang="scss"></style>
